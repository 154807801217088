// Use this stylesheet for print styles only.

* {
  background-color: transparent;
}

.sidebar,
#navigation,
#header-region,
#search-theme-form,
#footer,
.breadcrumb,
.tabs,
.feed-icon,
.links {
  display: none;
}

#page {
  width: 100%;
}

#content,
#content #content-inner,
.content,
.title {
  margin: 20px 0;
  width: auto;
}

a {

  &:hover,
  &:active,
  &:link,
  &:visited {
    color: black;
  }
}

// CSS2 selector to add visible href after links.
#content a {

  &:link:after,
  &:visited:after {
    content: " (" attr(href) ") ";
    font-size: 0.8em;
    font-weight: normal;
  }
}
